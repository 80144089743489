import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';

const Candidate = () => {
    const { searchString } = useParams();
    const [searchData, updateSearchData] = useState('loading')
    
    useEffect(() => {

        const bodyText = [
            {
              "action": "query",
              "object": "Application__c",
              "actionDetails": {
                "CandidateID__c": searchString
              }
            }
        ]

        getCandidateApplication()

        async function getCandidateApplication() {
            try {
                const searchData = await API.post('mainAppApi', '/items', { body: bodyText})
                console.log('searchData: ', searchData)
                updateSearchData(searchData)
            } catch (err) {
                const searchData = null
                updateSearchData(searchData)
                console.log({err})
            }
        }
    }, [searchString])

    const history = useHistory()
    function RescheduleButton(appointment) {
      history.push(`/${searchString}/${appointment}`)
    }

    if (searchData === 'loading') {
        return(
            <div>
                <h2 className="text-center">Searching for results...</h2>
            </div>
        )
    } else if (!Array.isArray(searchData) || !searchData.length) {
        return(
            <div>
                <h2 className="text-center">No candidate found</h2>
            </div>
        )
    } else {
        return(
            <div>
            <h2 className="text-center">{searchData[0]["Candidate__r.Name"]}'s Applications and Details</h2>
            <h3 className="text-center">{searchData[0]["Candidate__r.Name"]}'s Active Applications ({searchData.filter(application => {return application["Active__c"]}).length})</h3>
            {
              searchData
                .filter(application => {
                  return application["Active__c"]
                })
                .map((application, index) => (
                  <div className="record-box" key={index}>
                    <div className="row">
                      <div className="column">
                        Name: {application["Candidate__r.Name"]}<br/>
                        Job Title: {application["Requisition__r.Name"]}<br/>
                        Job Location: MISSING<br/>
                        Site: MISSING<br/>
                        Candidate ID: {searchString}<br/>
                        Requisition ID: {application["Requisition__c"]}<br/>
                        Application Step: {application["Step__c"]}<br/>
                        Application Substep: {application["Step_Substep__c"]}<br/>
                        Contingencies: MISSING<br/>
                      </div>
                      <div className="column">
                        Headcount Request: MISSING<br/>
                        Appointment: {application["Appointment__c"]}<br/>
                        Appt Status: {application["App_My_Job_Status__c"]}<br/>
                        Shift: MISSING<br/>
                        Pay Rate: MISSING<br/>
                        Regular/Temp: MISSING<br/>
                      </div>
                    </div>
                    {application["Appointment__c"] &&
                      <div className="reschedule-button">
                          <button onClick={RescheduleButton.bind(this, application["Appointment__c"])}>Reschedule Appointment</button>
                      </div>
                    }
                  </div>))
            }
            <h3 className="text-center">{searchData[0]["Candidate__r.Name"]}'s Inactive Applications ({searchData.filter(application => {return !application["Active__c"]}).length})</h3>
            {
              searchData
                .filter(application => {
                  return !application["Active__c"]
                })
                .map((application, index) => (
                  <div className="record-box" key={index}>
                    <div className="row">
                      <div className="column">
                        Name: {application["Candidate__r.Name"]}<br/>
                        Job Title: {application["Requisition__r.Name"]}<br/>
                        Job Location: MISSING<br/>
                        Site: MISSING<br/>
                        Candidate ID: {searchString}<br/>
                        Requisition ID: {application["Requisition__c"]}<br/>
                        Application Step: {application["Step__c"]}<br/>
                        Application Substep: {application["Step_Substep__c"]}<br/>
                        Contingencies: MISSING<br/>
                      </div>
                      <div className="column">
                        Headcount Request: MISSING<br/>
                        Appointment: {application["Appointment__c"]}<br/>
                        Appt Status: {application["App_My_Job_Status__c"]}<br/>
                        Shift: MISSING<br/>
                        Pay Rate: MISSING<br/>
                        Regular/Temp: MISSING<br/>
                      </div>
                    </div>
                  </div>))
            }
            </div> 
        )
    }
}

export default Candidate