import React, { useState, useEffect } from 'react';
import './App.css';
import { Auth, Hub } from 'aws-amplify';
import { Route } from 'react-router-dom';

import Login from './components/Login';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Candidate from './components/Candidate';
import Appointment from './components/Appointment';

const initialFormState = {
  formType: 'signInPage'
}

function App() {

  // check user authentication
  const [formState, updateFormState] = useState(initialFormState)
  const [user, updateUser ] = useState(null)
  const { formType } = formState
  
  useEffect((formState) => {

    checkUser()
    setAuthListener()

    async function checkUser() {
      try {
        const user = await Auth.currentAuthenticatedUser()
        updateUser(user)
        updateFormState(() => ({ ...formState, formType: "homePage" }))
      } catch (err) {
        console.log('User not signed in')
      }
    }
    async function setAuthListener() {
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signOut':
            updateFormState(() => ({ ...formState, formType: "signInPage" }))
            updateUser(null)
            break;
          default:
            break;
        }
      })
    }
  }, [])

  // const [searchData, updateSearchData] = useState('loading')

  return (
    <div className="App">
      {
        formType === 'signInPage' && (
          <div>
            <Login />
          </div>
        )
      }
      {
        formType === 'homePage' && (
          <div>
              {/* <Navbar searchData={searchData} /> */}
              <Navbar />

            <Route exact path='/'>
              <Home user={user} />
            </Route>

            <Route exact path='/:searchString'>
              <Candidate />
            </Route>

            <Route exact path='/:searchString/:appointment'>
              {/* <Appointment searchData={searchData}/> */}
              <Appointment />
            </Route>

          </div>
        )
      }
    </div>
  );
}

export default App;