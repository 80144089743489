const config = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:598a726e-c932-4c0a-aa89-e885edab43dc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tDOoBBRbq",
    "aws_user_pools_web_client_id": "grofcpei91ca3hn4fp39ehon7",
    "oauth": {
        "domain": "fast-track-user-pool.auth.us-east-1.amazoncognito.com",
        "scope": [
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/,https://master.dl7h9lbea607x.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://master.dl7h9lbea607x.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "mainAppApi",
            "endpoint": "https://1wldt8mtt0.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ]
};


export default config;