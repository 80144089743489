import React from 'react';
import { Auth } from 'aws-amplify';

const Home = ({user}) => {
    return(
        <div className="record-box">
            <h1>Home Page</h1>
            <p>Try searching for candidate ID: CID005672275</p>
            <p>User: {user ? JSON.stringify(user.username) : 'None'}</p>
            <button onClick={ () => Auth.signOut() }>Sign Out</button>
        </div>
    )
}

export default Home