import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';

const Appointment = () => {
    const { appointment } = useParams();
    const { searchString } = 'loading'
    const [searchData, updateSearchData] = useState('loading')
    
    useEffect(() => {

        const bodyText = [
            {
              "action": "query",
              "object": "Application__c",
              "actionDetails": {
                "Appointment__c": appointment
              }
            }
        ]

        getAppointmentDetails()

        async function getAppointmentDetails() {
            try {
                var searchData = await API.post('mainAppApi', '/items', { body: bodyText})
                console.log('searchData: ', searchData)
                searchData = searchData[0]["appointmentList"]
                console.log('searchData: ', searchData)
                updateSearchData(searchData)
            } catch (err) {
                const searchData = null
                updateSearchData(searchData)
                console.log({err})
            }
        }
    }, [appointment])

    const history = useHistory()
    function RescheduleButton(appt) {
      history.push(`/${searchString}/${appt}`)
    }

    if (searchData === 'loading') {
        return(
            <div>
                <h2 className="text-center">Searching for results...</h2>
            </div>
        )
    } else if (!Array.isArray(searchData) || !searchData.length) {
        return(
            <div>
                <h2 className="text-center">No appointments found</h2>
            </div>
        )
    } else {
        return(
            <div>
              <div>
                <h2 className="text-center">Current Appointment 4 Scheduled</h2>
                {
                  searchData
                    .filter(appt => {
                      return appt["Id"] === appointment
                    })
                    .map((application, index) => (
                      <div className="record-box" key={index}>
                        <div>
                          Amazon Warehouse Team Member<br/>
                          Missing REQ INFO!!
                        </div>
                        {application["Appointment__c"] &&
                          <div className="reschedule-button">
                              <button onClick={RescheduleButton.bind(this, application["Appointment__c"])}>Reschedule Appointment</button>
                          </div>
                        }
                      </div>))
                }
              </div>
            <h2 className="text-center">Appointments for Current Requisition<br/>
                (Recommended are based on existing candidate selections)</h2>
            {
              searchData
                .filter(appt => {
                  return appt["Id"] !== appointment
                })
                .map((application, index) => (
                  <div className="record-box" key={index}>
                    <div className="row">
                      <div className="column">
                        Name: {application["Candidate__r.Name"]}<br/>
                        Job Title: {application["Requisition__r.Name"]}<br/>
                        Job Location: MISSING<br/>
                        Site: MISSING<br/>
                        Candidate ID: {searchString}<br/>
                        Requisition ID: {application["Requisition__c"]}<br/>
                        Application Step: {application["Step__c"]}<br/>
                        Application Substep: {application["Step_Substep__c"]}<br/>
                        Contingencies: MISSING<br/>
                      </div>
                      <div className="column">
                        Headcount Request: MISSING<br/>
                        Appointment: {application["Appointment__c"]}<br/>
                        Appt Status: {application["App_My_Job_Status__c"]}<br/>
                        Shift: MISSING<br/>
                        Pay Rate: MISSING<br/>
                        Regular/Temp: MISSING<br/>
                      </div>
                    </div>
                  </div>))
            }
            </div> 
        )
    }
}

export default Appointment