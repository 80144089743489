import React from 'react';
import { Auth } from 'aws-amplify';

const Login = () => {
    return(
        <div className="center">
            <h1>Welcome to Fast Track</h1>
            <div className="login-button">
                <button onClick={() => Auth.federatedSignIn({provider: 'Salesforce'})}>Login with Salesforce</button>
            </div>
        </div>
    )
}

export default Login