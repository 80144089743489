import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';

const Navbar = (searchData) => {

    const history = useHistory()
    const [searchString, updateSearchString] = useState('')
    
    const SearchButton = () => {
        history.push(`/${searchString}`)
    }
    console.log(searchData)

    return(
        <div className="header-sticky">
            <nav className="navbar" role="navigation" aria-label="main navigation">
              <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-start">
                  <Breadcrumbs/ >
                </div>

                <div className="navbar-end">
                  <div className="navbar-item">
                    <input placeholder='Candidate ID' onChange={e => updateSearchString(e.target.value)} />
                    <button onClick={SearchButton}>Search</button>
                  </div>
                </div>    
              </div>
            </nav>
            {/* <h2 className="text-center">Current Appointment 4 Scheduled</h2>
            <div className="record-box">
                <div>
                  Amazon Warehouse Team Member<br/>
                  Missing REQ INFO!!
                </div>
            </div>
          <hr/> */}
        </div>
        
    )
}

export default Navbar